import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ChallengesActivityStatusCell from "./ChallengesActivityGroupStatusCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import TableHead from "@mui/material/TableHead";

const ChallengeActivityTable = (props) => {
  const detailsOpen = props.detailsOpen

  const headerYellow = '#FDD835'
  const bgYellow = '#f6f1bd'

  const tableCellStyles = {
    minWidth: "150px",
    maxWidth: "200px"
  }

  const HeaderTableCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.root}`]: {
      fontWeight: 'bold',
      // border: 'none',
      textAlign: "center",
      backgroundColor: headerYellow,
      padding: "7px 10px"
    },
  }))

  return(
      <TableRow>
        <TableCell style={{ backgroundColor: bgYellow, padding: 0 }} colSpan={8}>
          <Collapse in={detailsOpen} timeout='auto' unmountOnExit>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell align="center">Activity</HeaderTableCell>
                    <HeaderTableCell align="center">Type</HeaderTableCell>
                    <HeaderTableCell align="center">Prize</HeaderTableCell>
                    <HeaderTableCell align="center">Status</HeaderTableCell>
                    <HeaderTableCell align="center">Completed On</HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <>
                        <p style={{margin: 0, padding: 0}}>
                          Install Royal Match
                        </p>
                        <small>by 03/19/3023 8:55PM PDT</small>
                      </>
                    </TableCell>

                    <TableCell align="center">
                      Unlock Prize
                    </TableCell>

                    <TableCell align="center">
                      Amazon - $5.00
                    </TableCell>

                    <TableCell align="center">
                      Completed
                    </TableCell>

                    <TableCell align="center">
                      <p style={{margin: 0, padding: 0}}>
                        03/19/3023 8:55PM PDT
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
  )
}

export default ChallengeActivityTable