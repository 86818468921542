import React, { useContext, useState, useEffect } from "react";

import TableCell from '@mui/material/TableCell';

const ChallengesActivityGroupStatusCell = (props) => {
  const spotlight = props.spotlight

  const ltGreen = '#82B11D'
  const dkBlue = '#406AB4'
  const errorRed = '#D32F2F'
  const neutralGrey = '#B0BEC5'

  // Inv. Expired is detected by looking at the 'previous_state' and seeing if it's
  // 'invite_start' if the current state is 'expired'

  // It seems like the top-level state displays for the chip itself are going to need to be checked
  // and served from the server. The individual ChallengeActivity states are UI delegatable,
  // but the top-level statuses seem to need more aggregation and checking work to get right
  // since they rely on scanning the ChallengeActivity states against 2/3 as well as aggregated state judgments.

  const challengeStatusCellSyles = {
    backgroundColor: 'rgb(214 214 214)',
    // minWidth: 150,
    justifyContent: 'flex-start',
    minHeight: 33,
    display: 'flex',
    borderRadius: 25,
    alignItems: 'center',
    paddingLeft: '5px',
    maxHeight: '35px'
  }

  const circleStyle = {
    width: 25,
    height: 25,
    backgroundColor: chooseCircleColor(),
    borderRadius: 15,
    position: 'relative'
  }

  function processStateLabel(givenString){
    let string = givenString;
    // if(spotlightStates.positiveEndStates.includes(string)){ string = 'Completed' }
    // if(spotlightStates.negativeEndStates.includes(string)){ string = 'Expired' }
    // if(spotlightStates.progressStates.includes(string)){ string = 'In Progress' }
    // if(spotlightStates.availableStates.includes(string)){ string = 'Offer Available' }
    return string
  }

  function chooseCircleColor(){
    let color = ltGreen
    // if (spotlightStates.progressStates.includes(spotlight.state)){color = ltGreen}
    // if (spotlightStates.negativeEndStates.includes(spotlight.state)){color = errorRed}
    // if (spotlightStates.positiveEndStates.includes(spotlight.state)){color = dkBlue}
    return color
  }

  return(
    <TableCell>
      <div style={{display:'flex', justifyContent: 'center'}}>
        <div style={challengeStatusCellSyles}>
          <div style={circleStyle}></div>
          <p style={{margin: "auto", marginLeft:'8px', paddingRight: '10px'}}>{processStateLabel("In Progress")}</p>
        </div>
      </div>

    </TableCell>
  )
}

export default ChallengesActivityGroupStatusCell;