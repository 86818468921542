import React, {useState} from "react";
import {updateDevSettingCall} from "../../../../api/DevSetting";
import Button from "@mui/material/Button";
import {onUpdateDevSetting} from "../../../../models/DevSetting";

export default function SaveDevSetting({config, playerId, edit}) {
  const [saving, setSaving] = useState(false);

  function getRecords() {
    return Object.entries(config).filter(([_, entry]) => entry.value != null && entry.value !== "").map(([key, config]) => ({
      ...config,
      name: key,
      player_id: playerId,
    }));
  }

  async function handleSave() {
    const dev_settings = getRecords();

    if (saving || dev_settings.length === 0) {
      return;
    }

    setSaving(true);
    await onUpdateDevSetting({dev_setting: dev_settings});
    setSaving(false);
  }

  return (
    <Button variant="contained" size="small" onClick={handleSave} disabled={saving || !edit}>
      {saving ? "Saving..." : "Save"}
    </Button>
  )
}
