import axios from 'axios';
import { getHeaders,  baseUrl } from './utils';

export async function getDevSettingsCall(player) {

    try {
        const response  = await fetch(`${baseUrl}/dev_settings`,{
            method: "POST",
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(
              { player_id: player.id }
            )

        })


        const responseBack = await response.json()
        return responseBack
    } catch (err) {
        return { status: 500 };

    }
}

export async function updateDevSettingCall({ dev_setting }) {

    try {
        const response  = await fetch(`${baseUrl}/edit_dev_settings`,{
            method: "POST",
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(
              { dev_setting: dev_setting }
            )

        })

        const responseBack = await response.json()

        return responseBack
    } catch (err) {
        return { status: 401 };

    }
}
