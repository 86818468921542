import {useEffect, useState} from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@mui/material'
import Paper from '@mui/material/Paper';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import IMLoader from "../../../common/IMLoader";
import { getPlayerActivityGroups } from "../../../../models/Player/ChallengeActivityGroups";

import ChallengesTable from "./ChallengesTable";
import { ChallengesContext } from "./ChallengesContext";

const ChallengesContainer = () => {
  const [challengeGroups, setChallengeGroups] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    getPlayerActivityGroups().then((activityGroups)=>{
      console.log(activityGroups)
      setInitialStates(activityGroups)
      setLoading(false)
    }).catch((error)=>{
      console.error(error)
      setLoading(false)
    })

  },[])

  function setInitialStates(activityGroups){
    setChallengeGroups(activityGroups)
  }

  const noContentStyles = {
    fontSize: 20,
    color: 'grey',
    width: 'inherit',
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: 20
  }

  return (
  <>
    { loading &&
        <IMLoader message={"Loading Boost Challenges..."} mini={true}/>
    }
    {
      challengeGroups.length > 0 && !loading &&
      <ChallengesContext.Provider value={{
        challengeGroups,
        setChallengeGroups
      }}>
        <ChallengesTable></ChallengesTable>
      </ChallengesContext.Provider>
    }
    {
      challengeGroups.length === 0 && !loading &&
      <div style={noContentStyles}>No Boost Challenges to Show</div>
    }
  </>
  )
}

export default ChallengesContainer