import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ChallengesActivityStatusCell from "./ChallengesActivityGroupStatusCell";
import ChallengeActivityTable from "./ChallengeActivityTable";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import TableHead from "@mui/material/TableHead";


const ChallengeActivityGroupRow = () => {
  const [ detailsOpen, setDetailsOpen ] = useState(false)

  const ChallengeTableCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      minWidth: "200px",
      textAlign: "center"
    },
  }))

  const prizeStyle = {
    borderRadius: 15,
    margin: '0px 0px',
    padding: '3px 0px',
    backgroundColor: 'rgb(214 214 214)',
    width: "70%",
    marginLeft: "15%"
  }

  const FormattedDatesDisplay = () => {
    return(
      <>
        <p style={{margin: 0, padding: 0}}>
          03/18/3023 8:55PM PDT
        </p>
        <small><b>Expires on:</b> 03/19/3023 8:55PM PDT</small>
      </>
    )
  }

  function toggleActivityDetails(){
    if (detailsOpen){
      setDetailsOpen(false)
    } else {
      setDetailsOpen(true)
    }
  }

  return(
    <>
      <TableRow key={1}>

        <ChallengeTableCell style={{minWidth:0, maxWidth: '30px', paddingRight: '10px'}}>
          <ViewModuleIcon style={{color: "grey", maxWidth: '30px'}}></ViewModuleIcon>
        </ChallengeTableCell>

        <ChallengeTableCell style={{textAlign: "left"}}>
          <FormattedDatesDisplay/>
        </ChallengeTableCell>

        <ChallengeTableCell>
          <p>Spark</p>
        </ChallengeTableCell>

        <ChallengeTableCell>
          <p> 2 / 3</p>
        </ChallengeTableCell>

        <ChallengeTableCell align="center">
          <p style={prizeStyle}>15,000</p>
        </ChallengeTableCell>

        <ChallengesActivityStatusCell>
        </ChallengesActivityStatusCell>

        <ChallengeTableCell style={{textAlign: "center", minWidth: "150px"}}>
          <FormattedDatesDisplay/>
        </ChallengeTableCell>

        <ChallengeTableCell style={{minWidth: "50px"}}>
          <IconButton
            onClick={toggleActivityDetails}
          >
            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </ChallengeTableCell>
      </TableRow>

      <ChallengeActivityTable detailsOpen={detailsOpen}></ChallengeActivityTable>
    </>
  )

}

export default ChallengeActivityGroupRow