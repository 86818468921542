import * as React from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Stack,
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import ChallengeActivityGroupRow from "./ChallengeActivityGroupRow";
import Paper from '@mui/material/Paper';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ChallengesActivityStatusCell from "./ChallengesActivityGroupStatusCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ChallengesTable = () => {

  const HeaderTableCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.root}`]: {
      fontWeight: 'bold',
      border: 'none',
      textAlign: "center"
    },
  }))


  return(
    <Stack>
      <TableContainer>
         <TableHead>
            <TableRow>
              <HeaderTableCell />
              <HeaderTableCell style={{textAlign: "left"}}>Created</HeaderTableCell>
              <HeaderTableCell>Type</HeaderTableCell>
              <HeaderTableCell>Must Complete</HeaderTableCell>
              <HeaderTableCell>Prize</HeaderTableCell>
              <HeaderTableCell>Status</HeaderTableCell>
              <HeaderTableCell>Completed</HeaderTableCell>
              <HeaderTableCell />
            </TableRow>
          </TableHead>
        <TableBody>
          <ChallengeActivityGroupRow></ChallengeActivityGroupRow>
        </TableBody>
      </TableContainer>
    </Stack>

  )
}

export default ChallengesTable